<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>原厂库管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="info-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row>
          <a-col :span="6" class="first-search">
            <a-form-item label="用户名" class="form-item">
              <a-input v-decorator="['userName']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="6" :offset="1">
            <a-form-item label="原厂库" class="form-item">
              <a-input v-decorator="['factoryName']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="6" :offset="1">
            <a-form-item label="联系电话" class="form-item">
              <a-input v-decorator="['mobile']" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :span="2" :offset="2" class="end-search">
            <a-space>
              <a-button
                type="primary"
                icon="search"
                style="transform: translateY(5px)"
                @click="onSearch"
              >
                搜索
              </a-button>
              <a-button
                icon="undo"
                class="btn-reset"
                style="transform: translateY(5px)"
                @click="onResetSearch"
              >
                重置
              </a-button>
            </a-space>
          </a-col>
        </a-row>
        <a-row>
          <a-button type="primary" @click="onDetail('', 'add')">新增</a-button>
        </a-row>
        <a-table
          class="commodity-table"
          :data-source="tableData"
          :columns="tableColumns"
          :loading="loading"
          row-key="userName"
          :pagination="pagination"
          @change="onPage"
        >
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record.factoryId, 'detail')">查看详情</a>
            <a @click="onDetail(record.factoryId, 'modify')">修改</a>
            <a @click="onReset(record.userId)">重置密码</a>
            <a-popconfirm
              title="是否确认删除该原厂库?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onDelete(record.factoryId)"
            >
              <a>删除</a>
            </a-popconfirm>
          </a-space>
        </a-table>
      </a-form>
    </div>
  </a-layout-content>
</template>

<script>
import {
  fetchFactoryList,
  onDeleteFactory
} from "../../services/FactoryService";
import { resetPwd } from "../../services/UserService";
import { tsFormat } from "../../components/DateUtils";

const tableColumns = [
  {
    title: "用户名",
    dataIndex: "userName"
  },
  {
    title: "原厂库",
    dataIndex: "factoryName"
  },
  {
    title: "联系人电话",
    dataIndex: "mobile"
  },
  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action"
    }
  }
];
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      tableData: [],
      tableColumns,
      loading: false,
      pagination: {
        hideOnSinglePage: true
      }
    };
  },
  created() {
    this.onLoadList();
  },
  methods: {
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.onLoadList();
    },
    onLoadList() {
      const params = {
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 15,
        ...this.form.getFieldsValue()
      };
      this.loading = true;
      fetchFactoryList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tableData = resp.data.data.records;
            this.tableData.forEach(item => {
              item.createTime = tsFormat(item.createTime);
            });
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onDelete(factoryId) {
      onDeleteFactory(factoryId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.onLoadList();
        }
      });
    },
    onReset(userId) {
      resetPwd(userId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("密码重置成功");
          this.onLoadList();
        }
      });
    },
    onDetail(factoryId, type) {
      this.$router.push({
        name: "FactoryDetail",
        params: { type: type, factoryId: factoryId }
      });
    },
    onResetSearch() {
      this.form.resetFields();
      this.onLoadList();
    },
    onSearch() {
      this.pagination.current = 1;
      this.onLoadList();
    }
  }
};
</script>

<style scoped>
.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 5px 30px;
}

.commodity-table {
  margin-top: 10px;
}
.first-search /deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: auto;
}
</style>
