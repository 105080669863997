import { apiClient, baseURL } from "@/services/HttpService";

// 用户导入url
const userImpUrl = `${baseURL}/users/import`;

export { userImpUrl };

// 取用户列表
export function fetchUserList(searchParam) {
  return apiClient.post("/users", searchParam);
}
export function searchUserByKeyword(keyword) {
  return apiClient.get(`/users/search?keyword=${keyword}`);
}
// 新建用户
export function createUser(userParam) {
  return apiClient.post("/users/create", userParam);
}
export function modifyUser(userParam) {
  return apiClient.post("/users/modify", userParam);
}
// 删除用户
export function deleteUser(userId) {
  return apiClient.post(`/users/delete?userId=${userId}`);
}
// 加载用户信息
export function loadUserDetail(userId) {
  return apiClient.get(`/users/detail?userId=${userId}`);
}
// 重置密码
export function resetPwd(userId) {
  return apiClient.post(`/users/resetPwd?userId=${userId}`);
}
export function listUsers(from) {
  return apiClient.post("/users/searchByUser", from);
}
export function adoptUsers(from) {
  return apiClient.post("/users/adopt", from);
}

// 用户实名制核验
export function fetchUserModify(form) {
  return apiClient.post("/web/users/identity-auth", form);
}

// 修改密码
export function updateUserPassword(form) {
  return apiClient.post("/web/users/modify-password", form);
}

// 修改个人信息
export function updateUser(form) {
  return apiClient.post("/users/modify-user-information", form);
}

// 重新核验
export function modifyVerify(form) {
  return apiClient.post("/pioneer/modify", form);
}
