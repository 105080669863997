<template>
  <a-layout-content>
    <div>qqq</div>
  </a-layout-content>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped></style>
