<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item style="cursor: pointer">
          <a @click="onBack">原厂库管理</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ typeParam[type] }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="info-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row>
          <a-col :span="6" class="first-search">
            <a-form-item label="用户名" class="form-item">
              {{ type === "add" ? "系统自动生成" : info.userName }}
            </a-form-item>
          </a-col>
          <a-col :span="6" :offset="1">
            <a-form-item label="原厂库" class="form-item">
              <a-input
                v-if="type !== 'detail'"
                v-decorator="[
                  'factoryName',
                  { rules: [{ required: true, message: '请输入原厂库' }] }
                ]"
                allow-clear
              />
              <span v-else>{{ info.factoryName }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="6" :offset="1">
            <a-form-item label="联系电话" class="form-item">
              <a-input
                v-if="type !== 'detail'"
                v-decorator="[
                  'mobile',
                  {
                    rules: [
                      { required: true, message: '' },
                      { validator: phoneCheck.bind(this) }
                    ]
                  }
                ]"
                allow-clear
              />
              <span v-else>{{ info.mobile }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="6">
            <a-form-item label="创建时间" class="form-item">
              {{
                info.createTime
                  ? moment(info.createTime).format("YYYY-MM-DD")
                  : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <div class="examine-container">
          <a-button
            v-if="type !== 'detail'"
            class="button-item"
            @click="onCancel"
          >
            取消
          </a-button>
          <a-button
            v-if="type !== 'detail'"
            class="button-item"
            type="primary"
            @click="onAdd"
          >
            保存
          </a-button>
          <a-button v-else class="button-item" type="primary" @click="onModify">
            修改
          </a-button>
        </div>
      </a-form>
    </div>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import { fetchFactoryDetail, onAddFactory, onModifyFactory } from "@/services/FactoryService";

export default {
  data() {
    return {
      type: this.$route.params.type,
      typeParam: {
        detail: "详情",
        modify: "修改",
        add: "添加"
      },
      form: this.$form.createForm(this),
      moment,
      factoryId: this.$route.params.factoryId,
      info: {}
    };
  },
  created() {
    if (this.type !== "add") {
      this.onLoadDetail();
    }
  },
  methods: {
    onBack() {
      console.log("ddd");
      this.$router.push({ name: "FactoryManage" });
    },
    phoneCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(value)) {
        callbackFn("手机号码格式不正确");
        return;
      }
      callbackFn();
    },
    onCancel() {
      this.$router.go(-1);
    },
    onLoadDetail() {
      fetchFactoryDetail(this.factoryId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          if (this.type === "modify") {
            this.form.setFieldsValue({
              factoryName: this.info.factoryName,
              mobile: this.info.mobile
            });
          }
        }
      });
    },
    onModify() {
      this.type = "modify";
      this.onLoadDetail();
    },
    onAdd() {
      let form = {};
      this.form.validateFields((error, value) => {
        if (!error) {
          form = { ...value, factoryId: this.factoryId };
          if (this.type === "add") {
            onAddFactory(form).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("新增成功");
                this.$router.go(-1);
              }
            });
          } else {
            onModifyFactory(form).then(resp => {
              if (resp.data.code === "SUCCESS") {
                this.$message.success("修改成功");
                this.$router.go(-1);
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 5px 30px;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-item {
  margin-left: 20px;
}
</style>
