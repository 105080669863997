var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-content',[_c('div',{staticClass:"nav"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',{staticStyle:{"cursor":"pointer"}},[_c('a',{on:{"click":_vm.onBack}},[_vm._v("原厂库管理")])]),_c('a-breadcrumb-item',[_vm._v(_vm._s(_vm.typeParam[_vm.type]))])],1)],1),_c('div',{staticClass:"info-container"},[_c('a-form',{staticClass:"advanced-search-form",attrs:{"form":_vm.form}},[_c('a-row',[_c('a-col',{staticClass:"first-search",attrs:{"span":6}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"用户名"}},[_vm._v(" "+_vm._s(_vm.type === "add" ? "系统自动生成" : _vm.info.userName)+" ")])],1),_c('a-col',{attrs:{"span":6,"offset":1}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"原厂库"}},[(_vm.type !== 'detail')?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'factoryName',
                { rules: [{ required: true, message: '请输入原厂库' }] }
              ]),expression:"[\n                'factoryName',\n                { rules: [{ required: true, message: '请输入原厂库' }] }\n              ]"}],attrs:{"allow-clear":""}}):_c('span',[_vm._v(_vm._s(_vm.info.factoryName))])],1)],1),_c('a-col',{attrs:{"span":6,"offset":1}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"联系电话"}},[(_vm.type !== 'detail')?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'mobile',
                {
                  rules: [
                    { required: true, message: '' },
                    { validator: _vm.phoneCheck.bind(this) }
                  ]
                }
              ]),expression:"[\n                'mobile',\n                {\n                  rules: [\n                    { required: true, message: '' },\n                    { validator: phoneCheck.bind(this) }\n                  ]\n                }\n              ]"}],attrs:{"allow-clear":""}}):_c('span',[_vm._v(_vm._s(_vm.info.mobile))])],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":"创建时间"}},[_vm._v(" "+_vm._s(_vm.info.createTime ? _vm.moment(_vm.info.createTime).format("YYYY-MM-DD") : "-")+" ")])],1)],1),_c('div',{staticClass:"examine-container"},[(_vm.type !== 'detail')?_c('a-button',{staticClass:"button-item",on:{"click":_vm.onCancel}},[_vm._v(" 取消 ")]):_vm._e(),(_vm.type !== 'detail')?_c('a-button',{staticClass:"button-item",attrs:{"type":"primary"},on:{"click":_vm.onAdd}},[_vm._v(" 保存 ")]):_c('a-button',{staticClass:"button-item",attrs:{"type":"primary"},on:{"click":_vm.onModify}},[_vm._v(" 修改 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }